import { BaseElement, css } from 'Elements';
import { UrlParams, Lang } from 'Utils';

class TabGroupLg extends BaseElement {

  static get styles() {
    return [
      super.styles,
      css`      
        .toolbar {
          position:absolute;
          right:15px;
          height:30px;
          z-index:1;
        }

        .toolbar.disable {
          opacity:0.3;
          pointer-events:none;
        }
      `
    ]
  }

  static get properties() {
    return {
      level: { type: String },
      item: { type: Object },
      getPayload: { type: Function },
      translated: { type: Function }
    }
  }

  constructor() { 
    super();
    this.updateFieldsValue = this.updateFieldsValue.bind(this);
    this.tabs = {};
  }
  
  async updated(changedProperties) {
    super.updated(changedProperties);
    if (changedProperties.has('item')) {
      this.clickTab();
      // required to update form
      this.updateFieldsValue();
    }
  }

  disconnectCallback() {
    super.disconnectCallback();
    UrlParams.del(this.urlParam);
  }

  createTabRoot() {
    this.tabRoot = document.createElement('tab-group');
    this.tabRoot.setAttribute('level', this.level);
    this.tabRoot.setAttribute('size', 'medium');
    this.insertBefore(this.tabRoot, this.firstChild);
  }

  createTabLang(language) {
    const tab = document.createElement('sl-tab');
    tab.slot = 'nav';
    tab.panel = language.lg;
    tab.language = language;
    tab.innerHTML = language.flag;
    this.tabRoot.appendChild(tab);
    tab.addEventListener('click', (ev) => {
      ev.target.language.lg === Lang.mainlg 
        ? this.toolbar.classList.add('disable')
        : this.toolbar.classList.remove('disable')

      this.updateFieldsValue(ev);
    });

    for (const field of this.fields) {
      field.addEventListener('input', (ev) => {
        clearTimeout(this.inputTimeout);
        const target = ev.target;
        this.inputTimeout = setTimeout(() => {
          const value = target.textarea ? target.textarea.value : target.value;
          if (!this.item[field.name]) {
            this.item[field.name] = {};
          }
          this.item[field.name][field.getAttribute('lg')] = value;
          //console.log('input', `${field.name}.${field.getAttribute('lg')}`, value);
        }, 1);
      });
    }
    return tab;
  }

  updateFieldsValue(ev) {
    //console.log('updateFieldsValue', ev);
    if (!this.item) return;
    let tab;
    if (ev) {
      tab = ev.target.closest('sl-tab');
    } else {
      tab = this.selectedTab;
      if (!tab) return;
    }

    for (const field of this.fields) {

      //console.log('updateFieldsValue', tab.language.lg, field.name);

      field.setAttribute('lg', tab.language.lg);

      field.value = Lang.lookup(this.item, field.name, tab.language.lg, false);

      const tagName = field.tagName.toLowerCase();
      if (tagName === 'sl-input' || tagName === 'sl-textarea') {
        if (!field.value) field.value = '';
      }

      // fake textarea (markdown component ...)
      if (field.textarea) {
        field.textarea.value = field.value;
        field.textarea.innerText = field.value;
      }

      if (field.editor) {
        //console.log('updateFieldsValue', 'editor', field.value);
        field.editor.value = field.value;
      }
    }
  }

  addStyles() {
    const node = document.createElement('style');
    node.setAttribute('type', 'text/css');
    if (node.styleSheet){
      // This is required for IE8 and below.
      node.styleSheet.cssText = this.constructor.styles[1];
    } else {
      node.appendChild(document.createTextNode(this.constructor.styles[1]));
    }
    this.insertBefore(node, this.firstChild);
    return node;
  }

  createTabsLang() {
    for (const language of Lang.list) {
      const tab = this.createTabLang(language);
      this.tabs[language.lg] = tab;
      if (!this.firstTab) this.firstTab = tab;
    }
  }

  createToolbar() {
    this.toolbar = document.createElement('div');
    this.toolbar.className = 'toolbar';
    this.toolbar.innerHTML = '<button-translate size="small"></button-translate>';
    this.insertBefore(this.toolbar, this.firstChild);
    const btTranslate = this.toolbar.firstChild;
    btTranslate.getPayload = this._getTranslatePayload.bind(this);
    btTranslate.onTranslated = this._onTranslated.bind(this);
    this.addStyles();
  }

  _getTranslatePayload() {
    if (this.getTranslatePayload) {
      return this.getTranslatePayload();
    }

    const fields = this.querySelectorAll('[lg]');
    const payload = {};
    for (const field of fields) {
      payload[field.name] = Lang.lookup(this.item, field.name);
    }
    return payload;
  }

  _onTranslated(result) {
    for (const key of Object.keys(result)) {
      const field = this.querySelector(`[name="${key}"]`);
      if (field) {
        field.value = result[key];
        this.item[key][field.getAttribute('lg')] = result[key];
      } else {
        throw new Error(`field ${key} not found`);
      }
    }
  }

  async firstUpdated() {
    super.firstUpdated();

    // store every children having name attribute
    this.fields = Array.from(this.children).filter(child => child.name);

    this.createTabRoot();
    this.createTabsLang();
    this.createToolbar();

  }

  clickTab() {   
    clearTimeout(this.timeoutClickTab);
    this.timeoutClickTab = setTimeout(() => {
      this.urlParam = this.level;
      const currentTabLevel = UrlParams.get(this.urlParam) || Lang.mainlg;
      if (currentTabLevel && this.tabs[currentTabLevel]) {
        this.tabs[currentTabLevel].click();
      }
      if (!this.selectedTab) this.selectedTab = this.firstTab;
    }, 5);
  }

  createRenderRoot() {
    return this;
  }

}


customElements.define('tab-group-lg', TabGroupLg);