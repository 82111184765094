import { BaseElement, html, css, formCss } from 'Elements';
import { Fetcher, Lang } from 'Utils';

class SelectSimple extends BaseElement {
  static get styles() {
    return [
      formCss,
      css`
        :host {
          display: block;
          width:100%;
        }

        sl-option::part(base) {
          font-size:1em;
          font-family:Calibri;
          padding:3px;
        }
      `
    ]
  }

  static get properties() {
    return {
      loading: { type: Boolean },
      hoist: { type: Boolean },
      name: { type: String },
      label: { type: String },
      class: { type: String },
      size: { type: String },
      clearable: { type: Boolean },
      value: { type: String },
      detault: { type: String },
      sort: { type: String },
      placeholder: { type: String },
      displayFields: { type: Function },
    };
  }

  static get translations() {
    return [
      super.translations,
      {
        english:{
          translation: {
            no_item:'No item available, please add some.',
          },
        },
        french:{
          translation: {
            no_item:'Aucun élement disponible, veuillez en ajouter.',
          }
        }
      }
    ]
  }

  constructor() {
    super();
    this.debug = false;
    this.apiEndpoint = null; // ex: private/admin/items
    this.loading = true;
    this.name ='';
    this.size = 'medium';
    this.placeholder = '';
    this.clearable = false;
    this.value = '';
    this.items = [];
    this.stopSendEvent = false;
  }

  async visibleCallback() {
    super.visibleCallback();
    this._log.debug('visibleCallback');

    await this._loadItems();
  }

  async _onLanguageChanged(ev) {
    super._onLanguageChanged(ev);

    // hack to update input value, depending of the choosen language
    // because sl-select does not update this field when simple requestUpdate();
    this.stopSendEvent = true;
    const prev = this.value;
    this.value = undefined;
    await this.requestUpdate();
    this.value = prev;
    this.stopSendEvent = false;
  }

  sortLoadedItems(items) {
    if (!this.sort) return items;
    if (!items || !items.length) return [];
    items.sort((a, b) => {
      if (Lang.lookup(a, this.sort) < Lang.lookup(b, this.sort)) return -1;
      if (Lang.lookup(a, this.sort) > Lang.lookup(b, this.sort)) return 1;
      return 0;
    });
    return items;
  }

  async _loadItems() {
    this._log.debug('_loadItems', this.apiEndpoint);
    if (!this.apiEndpoint && !this.items &&!this.items.length ) {
      throw new Error('you MUST set apiEndpoint or array items');
    }

    this.select = this.select || this.shadowRoot.querySelector('sl-select');

    if (this.apiEndpoint) {
      this.loading = true;
      const response = await Fetcher.get(this.apiEndpoint);
      const loadedItems = response?.data;
      this.loadedItems = this.sortLoadedItems(loadedItems);
      this.displayedItems = JSON.parse(JSON.stringify(this.loadedItems));
      this.loading = false;
      return;
    }

    this.loading = false;
    this.loadedItems = this.sortLoadedItems(this.items);
    this.displayedItems = JSON.parse(JSON.stringify(this.loadedItems));
  }

  async sendEvent() {
    if (!this.stopSendEvent) {
      this.dispatchEvent(new CustomEvent('change', { detail: this.value }));
    }
  }  

  _onChange() {
    this.value = this.shadowRoot.querySelector('sl-select').value;
    this.sendEvent();
    // not sure, @TODO: check for language change
    //this.requestUpdate();
  }

  displayFieldsValue(item) {
    if (!this.displayFields) {
      throw new Error('you MUST override displayFields function');
    }
    return this.displayFields(item);
  }

  displayItems() {
    if (!this.loadedItems?.length) return '';

    return html`
      ${this.loadedItems.map(item => {
        const value = this.displayFieldsValue(item);
        if (!value) return '';

        return html`
          <sl-option value="${item._id.toString()}">
            ${this.displayAsTags
              ? html`<m-tag variant="primary" size="${this.size}" .tag=${item}>${value}</m-tag>` 
              : value
            }
          </sl-option>`
        })
      }
      `
  }

  render() {
    // Do not remove that, because select initiale value will not work without it
    if (!this.displayedItems) return html`<sl-select size="${this.size}" placeholder="..."></sl-select>`;
    if (!this.displayedItems.length) return html`${this._tl('no_item')}`;

    return html`
      <sl-select 
        placement="bottom"
        placeholder="${this.placeholder}"
        size="${this.size}"
        class="${this.class}"
        @sl-change=${this._onChange}
        value="${this.value || this.defaultValue || ''}"
        label="${this.label}"
        ?clearable="${this.clearable}"
        ?hoist="${this.hoist}"
        ${this.name ? `name="${this.name}"` : ''}
      >${this.displayItems()}
      </sl-select>
    `;
  }
}

export default SelectSimple;