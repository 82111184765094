import { BaseElement, html, unsafeHTML, css, highlight } from 'Elements';
import { Sleep, Lang, Fetcher } from 'Utils';

class SelectSearch extends BaseElement {

  static get styles() {
    return [
      css`
        :host {
          display:block;
          width:100%;
        }

        sl-dropdown {
          width:100%;
        }

        sl-dropdown::part(panel) {
          background-color:var(--sl-panel-background-color);
          display:flex;
          flex-direction:column;
          max-height:40vh;
        }

        sl-dropdown::part(popup) {
          margin-top:-5px;
        }

        sl-button {
          width:100%;
        }

        sl-button::part(base) {
          justify-content:flex-start;
          font-size:0.9em;
          line-height:initial;
        }

        sl-button::part(caret) {
          margin-left:auto;
        }

        sl-button::part(label) {
          width:100%;
          padding-left:0;
          padding-right:4px;
        }

        .spinner_bar {
          position: absolute;
          top: 14px;
          right: 5px;
          left: 5px;
        }

        .input_content {
          display: flex;
          justify-content: space-between;
          width: 100%;
        }

        .input_tags {
          display:flex;
          flex-wrap:wrap;
          gap:2px;
          margin-top:2px;
          margin-left:2px;
          width:100%;
          overflow:hidden;
        }

        .input {
          padding:2px;

        }

        .input_buttons {
          margin-top:4px;
          font-size:1.6em;
          margin-right:4px;
          display:flex;
          gap:2px;
        }

        .input_buttons m-icon {
          cursor:pointer;
          color:var(--sl-color-gray-400);
        }

        .input_buttons sl-icon {
          opacity:0.76;
          zoom:0.66;
          margin-top:3px;
          margin-right:7px;
          margin-left:7px;
        }

        .input_buttons m-icon:hover {
          color:var(--sl-color-gray-700) !important;

        }

        sl-input {
        }

        sl-menu {
          width: 100%;
          min-width: 100%; /* Assure la largeur minimale */
          max-width: 100%;
          flex:1;
        }

        sl-menu::part(base) {
          width: 100%;
        }

        sl-menu-item {
          width:100%;
        }

        sl-menu-item::part(base) {
          font-size:0.8em;
          line-height:1rem;
        }

        .placeholder {
          color: var(--sl-color-gray-500);
          font-size: 0.9rem;
          line-height: 1.6rem;
          padding-left: 10px;
          font-family:Calibri, sans-serif;
        }

        lit-virtualizer {
          min-height:100% !important;
          margin:0px;
        }

      `
    ]
  }

  static get properties() {
    return {
      value: { type: String },
      separator: { type: String },
      maxOptionsVisible: { type: Number, attribute: 'max-options-visible' },
      noSearch: { type: Boolean, attribute: 'no-search' },
      multiple: { type: Boolean },
      clearable: { type: Boolean },
      placeholder: { type: String },
      name: { type: String },
      items: { type: Array },
      virtualize: { type: Boolean },
      api: { type: String },
      primaryKey: { type: String, attribute: 'primary-key' },
      displayKey: { type: String, attribute: 'display-key' },
      renderItems: { type: Function },
    };
  }

  constructor() {
    super();
    this.debug = false;
    this.value = null;
    this.separator = null;
    this.maxOptionsVisible = 5;
    this.placeholder = '';
    this.firstTime = true;
    this.multiple = false;
    this.dropdownVisible = false;
    this.selected = [];  
    this.itemsById = {};
    this.displayItems = [];
    this.primaryKey = 'id';
    this.displayKey = 'name';
    this.api = '';
    this.apiLoading = false;
    this.renderItem = this.renderItem.bind(this);
    this.highlight = highlight;
  }

  connectedCallback() {
    super.connectedCallback();
    if (!this.virtualize) {
      const data = {};

      this.items = Array.from(this.querySelectorAll('sl-option')).map(option => {
        data[this.primaryKey] = option.getAttribute('value');
        data.text = option.getAttribute('text') || option.innerText;

        const prefix = option.querySelector('[part="prefix"]');
        data.prefix =  prefix ? prefix.outerHTML : '';

        const suffix = option.querySelector('[part="suffix"]');
        data.suffix = suffix ? suffix.outerHTML : '';
        return {...data}
      });

      this.items.map(item => this.itemsById[this.getKey(item)] = item);
      this.displayItems = this.items;
    }
  }

  getKey(item) {
    return Lang.lookup(item, this.primaryKey);
  }

  getLabelText(item) {
    return item.text || Lang.lookup(item, this.displayKey);
  }

  getPrefix(item) {
    return unsafeHTML(item.prefix ? item.prefix : '');
  }

  getSuffix(item) {
    return unsafeHTML(item.suffix ? item.suffix : '');
  }

  async updated(changedProperties) {

    if (changedProperties.has('value') && this.items && this.value) {
      if (this.separator) {
        this.selected = this.value.split(this.separator).map(item => item);
      } else {
        this.selected = [this.value];
      }
      
      if (this.virtualize) {
        this.items.map(item => this.itemsById[this.getKey(item)] = item);
        this.displayItems = this.items;
      }

      this.computeSelected();
      
      this.firstTime = false;
      this._log.debug('updated', this.value, this.selected);
      this.requestUpdate(); // required for initialisation having value
      return;
    }

    if (changedProperties.has('api')) {
      if (this.api) this.apiLoading = true;
    }
  }

  computeSelected() {
    if (this.separator && this.value) {
      this.selected = this.value.split(this.separator).map(item => item);
    } else {
      this.selected = [this.value];
    }

    // remove empty values, duplicates, and values that are not in the list
    this.selected = this.selected.filter((item, index, self) => item && self.indexOf(item) === index && this.itemsById[item]);
  }

  async visibleCallback() {
    super.visibleCallback();
      
    await this.loadData();

    // get the width of the sl-dropdown
    let width = 0;
    while (!width) {
      width = this.qs('sl-dropdown').offsetWidth;
      await Sleep(5);
    }

    // set the width of the sl-menu
    this.qs('sl-menu').style.width = width+'px';
    
  }

  async loadData() {
    if (!this.api) return;
    this.apiLoading = true;
    const response = await Fetcher.get(this.api);
    this.apiLoading = false;
    if (!response) return;
    this.items = response.data;
    this.items.map(item => this.itemsById[this.getKey(item)] = item);
    this.displayItems = this.items;
    this.computeSelected();
    this.requestUpdate();
  }

  async onChange(ev) {
    ev.stopPropagation();
    ev.preventDefault();
    const item = ev.detail.item;

    this._log.debug('onChange', item.value)

    if (this.multiple) {
      item.selected = !item.selected;
      if (this.selected.includes(item.value)) {
        // already selected, remove it
        this._log.debug('remove', item.value);
        this.selected = this.selected.filter(id => id.toString() !== item.value.toString());
      } else {
        // add it
        this._log.debug('add', item.value);
        this.selected.push(item.value);
      }
      this.value = this.selected.join(this.separator);
      this._log.debug('new value is', this.value);
    } else {
      this.selected = [item.value];
      this.value = item.value;
    }
    this.emitChange();
  }

  async onInput(ev) {
    this.q = ev.target.value.toLowerCase();
    this.displayItems = this.items.filter(item => this.getLabelText(item).toLowerCase().includes(this.q));
    if (this.virtualize) {
      this.qs('lit-virtualizer').items = this.displayItems;
    } else {
      this.requestUpdate();
    }
  }

  async onSearchClear(ev) {
    this.displayItems = this.items;
    this.requestUpdate();
  }

  async onDropDownShow(ev) {
    this._log.debug('onDropDownShow');
    await Sleep(10);
    this.dropdownVisible = true;
    const inputSearch = this.qs('sl-input');
    if (inputSearch) inputSearch.focus();
    this.renderVirtualizer();
  }

  async onDropDownHide(ev) {
    this._log.debug('onDropDownHide');
    this.dropdownVisible = false;
    // avoid mem leak !
    if (this.qs('lit-virtualizer')) {
      this.qs('lit-virtualizer').items = [];
    }
  }

  async onInputClear(ev) {
    ev.stopPropagation();
    ev.preventDefault();
    this.q = null;
    this.value = null;
    this.selected = [];
    this.emitChange();
  }

  async onRemoveTag(ev) {
    this._log.debug(`onRemoveTag: dropdownVisible=${this.dropdownVisible}`);
    if (this.dropdownVisible) {
      ev.stopPropagation();
      ev.preventDefault();

      this.selected = this.selected.filter(id => id !== ev.target.getAttribute('data-id'));
      this.value = this.selected.join(this.separator);
      this.emitChange();
      this.requestUpdate();
    }
  }

  emitChange() {
    this._log.debug('emitChange', this.value);
    this.dispatchEvent(new CustomEvent('sl-change', { detail: { value: this.value } }));
  }

  renderSelected() {
    this._log.debug('renderSelected', this.selected);
    if (this.apiLoading) {
      return '';
    }

    if (!this.selected.length) {
      return html`<div class="placeholder">${this.placeholder}</div>`;
    }

    if (this.multiple) {
      if (this.selected.length > this.maxOptionsVisible) {
        return this.selected.slice(0, this.maxOptionsVisible).map(id => {
          return html`
            <sl-tag type="primary" data-id=${id} removable size="small" @sl-remove=${this.onRemoveTag}>
              ${this.getPrefix(this.itemsById[id])}
              ${this.getLabelText(this.itemsById[id])}
            </sl-tag>
          `;
        }).concat(html`
          <sl-tag type="primary" size="small">+${this.selected.length - this.maxOptionsVisible}</sl-tag>
        `);
      }

      return this.selected.map(id => {
        return html`
          <sl-tag type="primary" data-id=${id} removable size="small" @sl-remove=${this.onRemoveTag}>
            ${this.getPrefix(this.itemsById[id])}
            ${this.getLabelText(this.itemsById[id])}
          </sl-tag>
        `;
      });
    } else {
      const id = this.selected[0];
      return html`
        <sl-tag type="primary" size="small">
          ${this.getPrefix(this.itemsById[id])}
          ${this.getLabelText(this.itemsById[id])}
        </sl-tag>
      `;
    }
  }

  renderItem(item) {
    return this.renderSlMenuItem(item);
  }

  renderVirtualizer() {
    if (!this.virtualize) {
      return;
    }
    const vt = this.qs('lit-virtualizer');
    vt.items = this.items;
    if (this.renderItems) {
      this.renderItems = this.renderItems.bind(this);
      vt.renderItem = this.renderItems;
    } else {
      vt.renderItem = this.renderItem;
    }
    vt.requestUpdate();
  }

  isSelected(item) {
    return this.selected.includes(this.getKey(item));
  }

  renderSlMenuItem(item) {
    if (this.renderItems) {
      return this.renderItems(item);
    }
    return html`
      <sl-menu-item type="checkbox" ?checked=${this.isSelected(item)} value="${this.getKey(item)}">
        ${this.getPrefix(item)}
        ${highlight(this.getLabelText(item), this.q)}
        ${this.getSuffix(item)}
      </sl-menu-item>
    `;
  }

  renderSpinner() {
    return html`
      ${this.api
        ? this.apiLoading 
          ? html`<div class="spinner_bar"><sl-progress-bar style="--height: 2px;" indeterminate></sl-progress-bar></div>`
          : ''
        : ''
      }`
  }

  renderInputIcons() {
    if (this.apiLoading) {
      return '';
    }

    return html`
      <div slot="suffix" class="input_buttons">
        ${this.selected.length
          ? this.clearable
            ? html`<sl-icon name="x-circle-fill" library="system" aria-hidden="true" @click=${this.onInputClear}></sl-icon>`
            : ''
          : ''
        }
        <sl-icon library="system" name="chevron-down" aria-hidden="true"></sl-icon>
      </div>`
  }

  setCustomValidity(msg) {
    this.qs('input').setCustomValidity(msg);
  }

  reportValidity() {
    this.qs('input').reportValidity();
  }

  render() {
    return html`
      <sl-dropdown hoist distance="0" @sl-show=${this.onDropDownShow} @sl-hide=${this.onDropDownHide}>
        <sl-button size="small" slot="trigger" ?disabled="${this.apiLoading}">
          <div class="input_content">
            <div class="input_tags">
              ${this.renderSpinner()}
              ${this.renderSelected()}
              <input type="text" style="border:0px;height:0px;opacity:0;position:absolute;bottom:0px;" name="error"/>
            </div>
            ${this.renderInputIcons()}
          </div>
        </sl-button>        

        ${this.noSearch
          ? ''
          : html` 
            <div class="input">
              <sl-input size="small" clearable @sl-input=${this.onInput} @sl-clear=${this.onSearchClear}>
                <m-icon name="search" slot="suffix"></m-icon>
              </sl-input>
            </div>
              `
        }

        <sl-menu @sl-select=${this.onChange}>
          ${this.virtualize
            ? html`<lit-virtualizer scroller></lit-virtualizer>`
            : this.displayItems.map(item => this.renderSlMenuItem(item))
          }
        </sl-menu>
      </sl-dropdown>
    `;
  }
}

customElements.define('select-search', SelectSearch);